/**
 * Created by mjwunderlich on 6/5/15.
 */

(function() {

  var CheckoutCCImpl = {

    _cc: [],

    _editCCModal: '#edit-cc-modal',
    _deleteCCModal: '#delete-cc-modal',

    _editCCModalForm: '#edit-cc-modal-form',

    _editCCButton: '.show-edit-cc-modal',
    _addCCButton: '.show-add-cc-modal',
    _deleteCCButton: '.show-delete-cc-modal',

    init: function() {

    },

    fetchCreditCardsFromServer: function() {
        var url = '/cart/user/cc';
        var self = this;

      var userHasSavedCreditCards = function(response) {
        self._loadCreditCards(response.cards);

        $(function() {
          self._displayCreditCards(response.cards_html);
        });
      };

      var userDoesNotHaveSavedCreditCards = function() {
        $(function() {
          CheckoutUI.showOneTimePayment();
        });
      };

      this._post(url, {}, userHasSavedCreditCards, userDoesNotHaveSavedCreditCards);
    },

    fetchCCInfo: function(token, callback) {
      var url = '/cart/user/cc/' + token;
      this._post(url, null, function (response) {
        if (response.error == 1) {
          Notify.error('Error Looking up Credit Card. Please try again.');
        }
        else {
          callback && callback( response );
        }
        return false;
      });
    },

    postEditCC: function() {
      var self = this;
      var data = Utils.Form.serialize('#edit-cc-modal-form');
      var url = '/cart/cc/update';
      this._post(url, data, function (response) {
        if ( !response.error ) {
          self.hideEditCCModal();
          Notify.success( response.message );
        }
      });
    },

    postDeleteCC: function(token) {
      var self = this;
      var data = Utils.Form.serialize('#delete-cc-modal-form');
      var url = '/cart/cc/delete';
      this._post(url, data, function (response) {
        if ( !response.error ) {
          self.hideDeleteCCModal();
          Notify.success( response.message );
          $('tr.cc-' + token).fadeOut(400, function() {
            $(this).remove();

            // If there are no more credit cards in list, then hide the list and show the one-time-payment form
            if ($('tr.cc-item').length == 0) {
              CheckoutUI.hideCCList();
              CheckoutUI.showOneTimePayment();
            }
          });
        }
      });
    },

    showEditCCModal: function(token) {
      var self = this;

      this.fetchCCInfo(token, function (response) {
        // Timeout is necessary to avoid an angular Digest In Progress error.
        $.each(response, function (k, v) {
          $('#edit-cc-modal-form input[name="' + k + '"]').val( v );
          $('#edit-cc-modal-form select[name="' + k + '"]').val( v );
        });

        $('#edit-cc-modal-form input[name="token"]').val( response.token );

        $(self._editCCModal).modal({
          'show': true
        });
      });
    },

    showDeleteCCModal: function(token) {
      var self = this;

      $('#delete-cc-modal-form input[name="token"]').val( token );
      $('#delete-cc-modal-form').unbind('submit').submit(function (event) {
        event.preventDefault();
        self.postDeleteCC(token);
      });

      $(this._deleteCCModal).modal({
        'show': true
      });
    },

    hideEditCCModal: function() {
      $(this._editCCModal).modal('hide');
    },

    hideDeleteCCModal: function() {
      $(this._deleteCCModal).modal('hide');
    },

    setupEditCCModalValidation: function() {
      var self = this;
      Utils.Validation.getOrSetupFormValidation(this._editCCModalForm, {

      }, function (form) {
        self.postEditCC();
      });
    },

    _loadCreditCards: function(cards) {
      var index;

      this._cc.length = 0;

      if (typeof cards === "undefined") {}
      else {
          for (index = 0; index < cards.length; ++index) {
              this._cc.push( cards[index] );
          }
      }
    },

    _displayCreditCards: function(jsonHtmlTemplate) {
      if (this._cc.length === 0) {
        CheckoutUI.showOneTimePayment();
        return;
      }

      var template = Utils.Template.grabTemplateFromJson(jsonHtmlTemplate);
      $('#ccs-list tbody').prepend(template);
      $('.card-info').show();

      // Hide the one-time-payment fields if a default saved credit-card is selected
      if ( $('input.cc-token:checked', template).length > 0 ) {
        CheckoutUI.hideOneTimePayment();
      }
      else {
        $('#ccs-list input.show-one-time-payment').attr('checked', 'checked');
        CheckoutUI.showOneTimePayment();
      }

      this._bootstrapCreditCards();
    },

    _bootstrapCreditCards: function() {
      $('.cc-item').each(function (index, item) {
        $('.show-edit-cc-modal', $(item)).unbind('click').click(function (event) {
          event.preventDefault();

          // Display the edit-cc modal
        });
        $('.show-delete-cc-modal', $(item)).unbind('click').click(function (event) {
          event.preventDefault();

          // Display the delete-cc modal
        });

        $('.hide-one-time-payment', $(item)).unbind('click').click(function (event) {
          CheckoutUI.hideOneTimePayment();
        });
      });

      $(this._editCCButton).unbind('click').click(function (event) {
        event.preventDefault();
        CheckoutCC.showEditCCModal( $(this).attr('data-cc-token') );
      });

      $(this._deleteCCButton).unbind('click').click(function (event) {
        event.preventDefault();
        CheckoutCC.showDeleteCCModal( $(this).attr('data-cc-token') );
      });

      this.setupEditCCModalValidation();
    },

    _post: function(url, data, callback, errorCallback) {
      $.ajax(url, {
        type: 'post',
        headers: {'X-CSRF-TOKEN': $('body').attr('data-csrf')},
        data: data,
        success: function(response) {
          if (response.error) {
            Log.error(response.message);
            Log.error(response.trace);
            errorCallback && errorCallback();
          }
          else {
            callback && callback(response);
          }
        },
        error: function(xhr, status, errorThrown) {
          Log.error(xhr.responseText);
          Log.error(errorThrown);
          errorCallback && errorCallback();
        }
      });
    }
  };

  // Export
  window.CheckoutCC = CheckoutCCImpl;

})();
