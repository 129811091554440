/**
 * Created by mjwunderlich on 6/4/15.
 */

(function() {

  var CartPromoImpl = {

    _itemPromo: '.promo-item',

    _formPromo: 'form#postpromo',
    _tablePromo: '.promos_applied table',
    _removePromoA: 'a.remove',

    _validator: null,

    _setupPromoValidation: function() {
      var self = this;

      this._validator = $(this._formPromo).validate({
        rules: {
          promo: {
            promo_code: {
              required: true,
              minlength: 4,
              maxlength: 20
            }
          }
        },

        errorClass: 'validationError',

        highlight: function (element, errorClass, validClass) {
          $(element).parent('div').addClass(errorClass);
          $('.error', $(element).parent('div')).show();
        },

        unhighlight: function (element, errorClass, validClass) {
          $(element).parent('div').removeClass(errorClass);
          $('.error', $(element).parent('div')).hide();
        },

        errorPlacement: function(error,element) {
          // Display the error message
          $('.formError', $(element).parent('div')).text( $(error).text() );
        },

        submitHandler: function(form) {
          var promo_code = $('input[name="promo_code"]', form).val();
          self.postPromo(promo_code);
        }
      });

      return this._validator;
    },

    _getPromoValidator: function() {
      return this._validator || this._setupPromoValidation();
    },

    _getPromoTable: function() {
      return $( this._tablePromo );
    },

    _getPromoForm: function() {
      return $( this._formPromo );
    },

    init: function() {
      this._setupPromoValidation();
    },

    resetPromoForm: function() {
      $( 'input[name]', this._getPromoForm()).val('');
      $( '.error', this._getPromoForm()).hide();
      $( '.validationError', this._getPromoForm()).removeClass('validationError');
      this._getPromoValidator().resetForm();
    },

    _showPromo: function(response) {
      var template = Utils.Template.grabTemplateFromJson( response.promo_template );
      this.bootstrapPromo(template, response.promo_code);
      this._getPromoTable().append( template );

      CartCheckoutUI.updateTotal( response.total );
      CartCheckoutUI.updateSubtotal( response.subtotal );
      CartCheckoutUI.showDiscounts();
      CartCheckoutUI.showSubtotal();
    },

    postPromo: function(code) {
      var self = this;

      $.ajax('/cart/promo', {
        type: 'post',
        headers: {'X-CSRF-TOKEN': getCsrfToken()},
        data: {
          promo: code
        },
        success: function(response) {
          // Render the promo
          self._showPromo(response);
          self.resetPromoForm();
        },
        error: function(xhr, status, error) {
          // Mark the promo field as erroneous
          var validator = self._getPromoValidator();
          validator.showErrors({
            promo_code: xhr.responseText
          });
        }
      });
    },

    removePromo: function(code) {
      var self = this;

      $.ajax('/cart/promo/delete', {
        type: 'post',
        headers: {'X-CSRF-TOKEN': getCsrfToken()},
        success: function(response) {
          $( self._tablePromo + ' tr').remove();

          CartCheckoutUI.updateTotal( response.total );
          CartCheckoutUI.updateSubtotal( response.subtotal );
          CartCheckoutUI.hideDiscounts();
          CartCheckoutUI.hideSubtotal();
        }
      });
    },

    bootstrapPromo: function(promoTemplate, code) {
      var self = this;
      $( this._removePromoA, promoTemplate).attr('data-promo-code', code);
      $( this._removePromoA, promoTemplate).unbind('click').click(function (event) {
        self.removePromo( $(this).attr('data-promo-code') );
      });
    },

    bootstrapPreloadedPromoItems: function() {
      var self = this;
      $( this._itemPromo).each( function(index, item) {
        var template = $( item );
        var code = $( self._removePromoA, template).attr('data-promo-code');
        self.bootstrapPromo( template, code );
      });
    }

  };

  window.CartPromo = CartPromoImpl;

})();
