/**
 * Created by mjwunderlich on 8/18/16.
 */

(function() {
  
  var CheckoutVATImpl = {

    _billingCountry: 'select#billing_country',

    _VATNumberIsExempt: false,
    _VATNumberIsValid: true,
    _VATNumberCountry: '',
    _VATRate: 0,
    _VATPercent: 0,
    _VATTotal: 0,
    _VATExchangeRate: 1.0,
    _TotalWithVAT: 0,

    bootstrapUI: function () {
      var self = this;

      // VAT id for tax exemption
      $('input#billing_vat_id').change(function () {
        self.hideVATInfo();
        var input = this;

        var val = $(input).val();

        self.validateVATNumber(val, function (is_valid, vat_number, message) {
          CheckoutVAT.updateVATTax(false);
        });
      });

      // Create a callback to be invoked whenever there's a currency change
      CartCheckout.addCurrencyChangeCallback(function () {
        // Cache the exchange rate locally
        // Make sure that the exchange rate is a float, and if not provided, use 1.0
        self._VATExchangeRate = +(CartCheckoutUI._exchangeRate || 1.0);

        // Redisplay VAT tax info
        CheckoutVAT.updateVATTax(false);
      });

    },

    clearVAT: function() {
      this._VATRate = 0;
      this._VATNumberIsExempt = false;
      this._VATPercent = 0;
      this._VATTotal = 0;
    },

    showVATErrorMessage: function (message) {
      $('.info-vat').hide();
      window.checkout_validator.showErrors({
        billing_vat_id: message || "Invalid VAT Number"
      });
    },

    clearVATErrorMessage: function () {
      //Internal $.validator is exposed through $(form).validate()
      var validator = $('form#checkout-form').validate();
      //Iterate through named elements inside of the form, and mark them as error free
      $('#checkout-form [name]').each(function () {
        var name = $(this).attr('name');
        if (name == "billing_vat_id") {
          console.log("Removing error for element: " + name);
          validator.successList.push(this);//mark as error free
          validator.successList.push($(this));//mark as error free
          validator.successList.push($(this).closest("inputText"));//mark as error free
          validator.showErrors();//remove error messages if present
        }
      });
      validator.resetForm();//remove error class on name elements and clear history
      validator.reset();//remove all error and success data

      $("#clearButton").click(function () {
        $("label.error").hide();
        $(".error").removeClass("error");
      });
    },

    showVATExemptMessage: function () {
      $('.info-vat').hide();
      $('.info-vat-exempt').show();
      this._VATNumberIsValid = true;
      this._VATNumberIsExempt = true;
    },

    showVATNonExemptMessage: function () {
      $('.info-vat').hide();
      $('.info-vat-non-exempt').show();
      this._VATNumberIsValid = true;
      this._VATNumberIsExempt = false;
    },

    hideVATInfo: function () {
      $('.info-vat').hide();
      //this._VATNumberIsValid = false;
      //this._VATNumberIsExempt = false;
    },

    showVATField: function () {
      $('section.vat').show();
    },

    hideVATField: function () {
      $('section.vat').hide();
    },

    validateVATNumber: function (vatNumber, callback) {
      var self = this;
      var country = this.getBillingCountry();
      var non_exempt = ['uk', 'gb'];

      // Blank numbers are valid, but not exempt
      if (vatNumber == "") {
        self._VATNumberIsExempt = false;
        self._VATNumberIsValid = true;
        self.clearVATErrorMessage();
        self.updateVATNumberInfo();
        callback(true);
        return;
      }

      var success = function (response) {
        var is_valid = ('valid' in response) ? +response.valid : 0;
        self._VATNumberIsExempt = (is_valid && non_exempt.indexOf(country) == -1);
        self._VATNumberIsValid = is_valid;
        self._VATNumberErrorMessage = response.message || "Invalid VAT Number";
        self.updateVATNumberInfo();
        callback(is_valid);
      };
      var error = function (message, trace) {
        self._VATNumberIsExempt = false;
        self._VATNumberIsValid = false;
        self._VATNumberErrorMessage = message || "Error while validating VAT Number";
        self.updateVATNumberInfo();
        callback(false);
      };
      Utils.Ajax.getAjax('/vat/op/validate', {vat_number: vatNumber}, success, error);
    },

    hasVATNumber: function () {
      var billing_vat_id = $('input#billing_vat_id').val();

      if (!billing_vat_id) {
          return false;
      }

      return billing_vat_id.length > 0
    },

    updateVATNumberInfo: function () {
      var non_exempt = ['uk', 'gb'];
      var country = this.getBillingCountry();
      this._VATNumberIsExempt = (this._VATNumberIsValid && this.hasVATNumber() && non_exempt.indexOf(country) == -1);

      if (!this._VATRate || non_exempt.indexOf(country) != -1) {
        this.hideVATField();
      }
      else {
        this.showVATField();
        this.hideVATInfo();
        if (this._VATNumberIsValid) {
          if (this.hasVATNumber()) {
            if (this._VATNumberIsExempt) {
              this.showVATExemptMessage();
            }
          }
        }
        else if (this.hasVATNumber() && !this._VATNumberIsValid) {
          this.showVATErrorMessage(this._VATNumberErrorMessage);
        }
      }
    },

    getTotalBeforeVATTax: function () {
      var total = $('table.totals .checkout-final').attr('data-total');
			return parseFloat(total);
    },

    getVATRate: function (countryCode, callback) {
      Utils.Ajax.getAjax('/vat/op/rate', {country_code: countryCode}, function (response) {
        // Got a successful VAT rate
        var rate = +response.rate;
        callback(rate, rate / 100.0);
      }, function (message, trace) {
        // Error getting the VAT rate -- simply set to 0
        callback(0, 0);
      });
    },

    getBillingCountry: function () {
      return CheckoutUI.getBillingCountry();
    },

    updateVATTax: function (fullReload) {
      var totalBeforeVat = this.getTotalBeforeVATTax();
      var billingCountry = this.getBillingCountry();
      var self = this;

      if (fullReload || !this._VATRate) {
        this.getVATRate(billingCountry, function (rate, percent) {
          self._VATRate = rate;
          self._VATPercent = percent;
          self._VATTotal = totalBeforeVat * percent;
          self._TotalWithVAT = totalBeforeVat + self._VATTotal;

          self.updateVATNumberInfo();

          if (rate > 0) {
            self.showVATTax(self._VATRate, self._VATTotal, totalBeforeVat + self._VATTotal);
          }
          else {
            self.hideVATTax(totalBeforeVat);
          }
        });
      }
      else {
        self.updateVATNumberInfo();
        this._VATTotal = totalBeforeVat * self._VATPercent;
        this._TotalWithVAT = self._VATTotal + totalBeforeVat;

        if (this._VATRate) {
          this.showVATTax(this._VATRate, this._VATTotal, this._TotalWithVAT)
        }
        else {
          this.hideVATTax(totalBeforeVat);
        }
      }
    },

    showVATTax: function (vatRate, vatTotal, checkoutTotal) {
      if (!this._VATNumberIsExempt) {
        $('.vat-rate').text('Rate: ' + vatRate + '%');
        $('.vat-total').text( CartCheckoutUI.formatCurrency(vatTotal) );
        $('.vat-tax-only').show();
        $('.vat-tax-container.vat-tax-only').css('backgroundColor', '#90EE90');
        $(".vat-tax-container.vat-tax-only").animate({
            backgroundColor: "transparent"
        }, 3000);

            $('.checkout-final').text( CartCheckoutUI.formatCurrency(checkoutTotal) );
            $("#checkout-form input[name=_amount]").val(checkoutTotal);


          if (this._VATNumberIsValid && this.hasVATNumber())
              this.showVATNonExemptMessage();
      }
      else {
        $('.vat-rate').text('Rate: 0% (VAT exempt)');
        $('.vat-total').text(CartCheckoutUI.formatCurrency(0));
        $('.vat-tax-only').show();
        $('.vat-tax-container.vat-tax-only').css('backgroundColor', '#90EE90');
        $(".vat-tax-container.vat-tax-only").animate({
            backgroundColor: "transparent"
        }, 3000);

        // If exempt, then make sure to show original amount without VAT
        $('.checkout-final').text( CartCheckoutUI.formatCurrency(this.getTotalBeforeVATTax()) );
        $("#checkout-form input[name=_amount]").val(this.getTotalBeforeVATTax());

        if (this._VATNumberIsValid) {
          this.showVATExemptMessage();
        }
      }
    },

    hideVATTax: function (total) {
        var totalBeforeVat = total || this.getTotalBeforeVATTax();
        $('.vat-rate').text('Rate: 0%');
        $('.vat-total').text('0.00');
        $('.vat-tax-only').hide();
        $('.checkout-final').text(CartCheckoutUI.formatCurrency(this.getTotalBeforeVATTax()));
        $("#checkout-form input[name=_amount]").val(this.getTotalBeforeVATTax());
        this.hideVATInfo();
    },

    getTotalsAsURIParams: function () {
      var params = {
        vat_rate: this._VATRate,
        vat_total: this._VATNumberIsExempt ? 0 : this._VATTotal,
        vat_exempt: this._VATNumberIsExempt ? 1 : 0,
        vat_region: this.getBillingCountry(),
        grand_subtotal: this.getTotalBeforeVATTax(),
        grand_total: this._VATTotal + this.getTotalBeforeVATTax()
      };

      var uri = "";
      var key;
      for (key in params) {
        if (uri.length)
          uri += "&";
        uri += key;
        uri += "=";
        uri += params[key];
      }

      return uri;
    }

  };

  window.CheckoutVAT = CheckoutVATImpl;
  
})();
