(function() {

  /**
   * CLASS NotifyInstance
   *
   * @constructor
   */
  function NotifyInstance(level, message, options) {
    this.level = level || 0;
    this.message = message;
    this.options = this.parseOptions(options);
    this.isDisplayed = false;
    this.duration = this.options.duration || 0;
    this.element = null;
    this.timeout = null;
  }

  /**
   * Displays a notification object.
   *
   */
  NotifyInstance.prototype.display = function() {
    if (this.isDisplayed) {
      Log.warn('notify instance has already been displayed');
      return;
    }

    this._createElement();
    this._addCloseButton();
    this._addMessageIcon();

    var notificationStage = Notify._container();
    notificationStage.append(this.element);

    this._presentElement();
  };


  NotifyInstance.prototype._createElement = function() {
    var element = $('<div>').css({
      position: 'relative',
      display: 'block',
      opacity: 0,
      top: '0px',
      marginBottom: '10px',
      userSelect: 'none'
    }).addClass('notify ' + Notify._cssClasses[this.level]);

    element.addClass(this.options.cssClass);

    // Apply the css (if any)
    for (var css in this.options.css) {
      Log.verbose('applying notify css rule: ' + css + ' = ' + this.options.css[css]);
      element.css(css, this.options.css[css]);
    }

    $('<span>').html(this.message).appendTo(element);

    this.element = element;
  };


  NotifyInstance.prototype._presentElement = function() {
    var self = this;
    var animateCss = { opacity: '1' };

    $(this.element).animate(animateCss, 400, function() {

      if (self.options.onShow) {
        self.options.onShow(self.element);
      }

      if (self.duration && self.level != Notify.LEVEL_ERROR) {
        self.timeout = setTimeout(function () {
          self._dismiss();
        }, self.duration);
      }
    });

    this.isDisplayed = true;
  };


  NotifyInstance.prototype._dismiss = function() {
    var self = this;

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }

    this.element.slideUp(300, function() {
      if (self.options.onHide) {
          self.options.onHide(self.element);
          self.element.remove();
      }
    });
    $("#wrapper").animate({ 'marginTop': '0px' });
    $("#header").animate({ 'marginTop': '0px' });

  };


  NotifyInstance.prototype._addCloseButton = function() {
    var self = this;
    var closeButton = $('<span>&times;</span>');
    closeButton.addClass('close');
    closeButton.css( {
      color: 'black',
      fontWeight: '800',
      float: 'right',
      cursor: 'pointer',
      userSelect: 'none'
    } );
    closeButton.click(function() {
      self._dismiss();
    });
    this.element.append(closeButton);
  };


  NotifyInstance.prototype._addMessageIcon = function() {
    var iconName;
    switch (this.level) {
      case Notify.LEVEL_ERROR:
        iconName = 'fa-exclamation-triangle';
        break;
      case Notify.LEVEL_INFO:
        iconName = 'fa-exclamation-circle';
        break;
      case Notify.LEVEL_SUCCESS:
        iconName = 'fa-check';
        break;
    }

    var icon = $('<i>');
    icon.addClass('fa ' + iconName);
    icon.css({ marginRight: '8px' });
    this.element.prepend(icon);
  };


  /**
   * Parses an options map passed in to NotifyInstance constructor.
   *
   * @param options the options map to parse
   * @returns the parsed/prepared options map
   */
  NotifyInstance.prototype.parseOptions = function(options) {
    options = options || {};

    // Allow for string durations so that common durations can be defined
    if (typeof options.duration == 'string') {
      options.duration = Notify._durations[ options.duration ];
      if (typeof options.duration == 'undefined') {
        delete options.duration;
      }
    }

    options = $.deepExtend(options, {
      cssClass: '',
      duration: 4500,
      logToConsole: false,
      onShow: function() { },
      onHide: function() { }
    });

    return options;
  };



  /**
   *
   * Notify control object
   * This is the only front-facing aspect of this API
   *
   */



  window.Notify = {
    _queue: [],
    _initialized: false,
    _minLevel: 0,
    _maxLevel: 5,
    _cssClasses: ['notify-verbose', 'notify-ok', 'notify-info', 'notify-warn', 'notify-error', 'notify-critical'].reverse(),
    _durations: {
      flash: 500,
      quick: 1000,
      standard: 1000,
      'default': 1000,
      long: 3000,
      prolonged: 5000
    },

    LEVEL_CRITICAL: 0,
    LEVEL_ERROR: 1,
    LEVEL_WARN: 2,
    LEVEL_INFO: 3,
    LEVEL_SUCCESS: 4,
    LEVEL_VERBOSE: 5,

    /**
     * Displays the notification contained at the given index
     * @param index
     * @private
     */
    _displayNext: function() {
      var n = Notify._queue.shift();
      n.display();
    },

    /**
     * Gets the appropriate container for notifications. If one is not provided by the DOM, then it is created
     * and inserted in a position relevant only to Bisnow.com external layout.
     *
     * @returns {*|jQuery|HTMLElement}
     * @private
     */
    _container: function() {
      var container = $('.notifications .inner');
      if (0 == container.length) {
        var n0 = $('<div>').addClass('inner');
        container = $('<div>').addClass('notifications').append(n0);
        $('.container .containerIn').prepend(container);
      }
      return container;
    },

    /**
     * Makes the notifications container scroll with the page, so that notifications are always visible from
     * anywhere in the page.
     *
     * @private
     */
    _makeContainerScroll: function() {
      var container = Notify._container();
      if (true == container.attr('_notify_scroll_ready')) {
        return;
      }

      // We do NOT want to make the body scrollable
      if (container.is('body')) {
        return;
      }

      $(window).on('scroll', function() {
        var scrollPos = $(document).scrollTop();
        var headerOffset = $('#header').offset();
        var headerHeight = $('#header').height();
        var subheaderHeight = $('subheader').not('.fixedHeader').height();

        if (subheaderHeight > 0) {
          subheaderHeight -= Math.min(76, scrollPos);
        }
        else {
          subheaderHeight = 0;
        }

        container.css({
          position: 'fixed',
          boxSizing: 'border-box',
          zIndex: 99999000,
					left: 0,
					right: 0,
					top: 0,
					marginTop: 0
          //width: $('.container .containerIn').width(),
          //top: headerOffset.top + headerHeight + subheaderHeight + 40
        });
      }).scroll();

      $(window).on('resize', function() {
        container.css({
          //width: $(container).parent().width()
        });
      });

      // Mark this container as already being scrollable
      container.attr('_notify_scroll_ready', true);
    },

    /**
     * Initializes the Notify object.
     * @private
     */
    _init: function() {
      if (Notify._initialized) {
        return;
      }

      Notify._makeContainerScroll();
      Notify._initialized = true;
    },

    /**
     * Display a notification message of the given level, using the options to control its appearance.
     * Options include:
     *  - cssClass: additional class(es) to apply to notification
     *  - css: css rules to apply
     *  - duration: duration the notification is diplayed; 0 to display until user dismissal
     *  - onShow: callback function executed when notification is shown
     *  - onHide: callback function executed when notification is hidden
     *
     * @param level the notification level (critical, error, warn, info, success
     * @param message the notification message
     * @param options
     */
    _notify: function(level, message, options) {
      Notify._init();
			$("#wrapper").css("margin-top", "31px");
			$("#header").css("margin-top", "31px");

      var n = new NotifyInstance(level, message, options);
      Notify._queue.push(n);
      Notify._displayNext();
      return n;
    },

    /**
     * Display a notification message of the given level, using the options to control its appearance.
     * Options include:
     *  - cssClass: additional class(es) to apply to notification
     *  - css: css rules to apply
     *  - duration: duration the notification is diplayed; 0 to display until user dismissal
     *  - onShow: callback function executed when notification is shown
     *  - onHide: callback function executed when notification is hidden
     *
     * @param level the notification level (critical, error, warn, info, success
     * @param message the notification message
     * @param options
     */
    notify: function(level, message, options) {
      Notify._notify(level, message, options);
    },

    error: function(message, options) {
      var n = Notify._notify(Notify.LEVEL_ERROR, message, options);
      if (n.options.logToConsole) {
        Log.error(message);
      }
    },

    warn: function(message, options) {
      var n = Notify._notify(Notify.LEVEL_WARN, message, options);
      if (n.options.logToConsole) {
        Log.warn(message);
      }
    },

    info: function(message, options) {
      var n = Notify._notify(Notify.LEVEL_INFO, message, options);
      if (n.options.logToConsole) {
        Log.info(message);
      }
    },

    success: function(message, options) {
      var n = Notify._notify(Notify.LEVEL_SUCCESS, message, options);
      if (n.options.logToConsole) {
        Log.info(message);
      }
    },

    verbose: function(message, options) {
      var n = Notify._notify(Notify.LEVEL_VERBOSE, message, options);
      if (n.options.logToConsole) {
        Log.verbose(message);
      }
    }
  };
})();
