(function () {

  /**
   * LOG Namespace
   *
   * Contains methods to log at various levels, including:
   *  1. ERROR level
   *  2. WARN level
   *  3. INFO level
   *  4. VERBOSE level
   *
   * By setting the log-level, it's possible to control what gets logged.
   * Only those log levels that are <= to the LOG_LEVEL will be logged.
   */
  window.Log = $.extend({}, {
    LOG_LEVEL_NONE: 0,
    LOG_LEVEL_ERROR: 1,
    LOG_LEVEL_WARN: 2,
    LOG_LEVEL_INFO: 3,
    LOG_LEVEL_VERBOSE: 4,
    LOG_LEVEL_ALL: 5,

    // The current logging level is set to VERBOSE
    LOG_LEVEL: 0,

    logMethods: null,

    init: function() {
      if (null != Log.logMethods) {
        return;
      }

      var emptyFunction = function() { /* this is an empty function */ };

      Log.logMethods = {};
      Log.logMethods.verbose = typeof console == 'object' ? (typeof console.log == 'function' ? console.log : emptyFunction) : emptyFunction;
      Log.logMethods.info = typeof console.info == 'function' ? console.info : Log.logMethods.verbose;
      Log.logMethods.warn = typeof console.warn == 'function' ? console.warn : Log.logMethods.info;
      Log.logMethods.error = typeof console.error == 'function' ? console.error : Log.logMethods.warn;
    },

    /**
     * This method wraps the console available to most modern browsers, and if available, it invokes its log methods.
     * Only logs those messages with a logLevel equal-to or less-than the current LOG_LEVEL.
     *
     * @param logThis the message/object to log to console
     * @param logLevel the logging level for this message
     */
    log: function (logThis, logLevel) {
      // If invalid logLevel, default to LOG_LEVEL_INFO
      if (typeof logLevel != 'number') {
        logLevel = Log.LOG_LEVEL_INFO;
      }

      // Only log messages up to LOG_LEVEL
      if (logLevel > Log.LOG_LEVEL) {
        return;
      }

      // Initialize the Logger
      Log.init();

      if (!console || typeof console != 'object') {
        return;
      }

      var logType = ([null, 'error', 'warn', 'info', 'verbose', null])[logLevel];
      if (typeof logType != 'string') {
        logType = 'verbose';
      }

      if (typeof logThis != 'string' && typeof logThis != 'number') {
        console.dir(logThis);
      }
      else {
        Log.logMethods[logType].apply(console, [logThis]);
      }
    },

    /**
     * Logs a VERBOSE-level message
     *
     * @param logThis the message/object to log to console
     */
    verbose: function (logThis) {
      Log.log(logThis, Log.LOG_LEVEL_VERBOSE);
    },

    /**
     * Logs an INFO-level message
     *
     * @param logThis the message/object to log to console
     */
    info: function (logThis) {
      Log.log(logThis, Log.LOG_LEVEL_INFO);
    },

    /**
     * Logs a WARN-level message
     *
     * @param logThis the message/object to log to console
     */
    warn: function (logThis) {
      Log.log(logThis, Log.LOG_LEVEL_WARN);
    },

    /**
     * Logs an ERROR-level message. This is the highest logging level.
     *
     * @param logThis the error/object to log to console
     */
    error: function (logThis) {
      Log.log(logThis, Log.LOG_LEVEL_ERROR);
    }
  });
})();