(function() {

  var CartOrdersImpl = {
		getOrder: function(csrf_token) {
			var self = this;

			$('.open-order').bind('click', function(e) {
				var orderID = $(this).attr('data-order-id');

				$.ajax('/order/' + orderID, {
					type: 'get',
					data: {'_token': csrf_token},
					success: function(response) {
						if (response.error) {
							alert('Error: ' + response.message);
						}
						else {
							$('#order-history-desktop').html(response.view);
							$('#order-history-mobile').html(response.view);
						}
					},
					error: function(xhr, status, error) {
						alert('System Error: ' + error);
					}
				});
			})
		},

 		getOrderInvoice: function(csrf_token) {

 			$('.open-order').bind('click', function(e) {
				var invoiceID = $(this).attr('data-invoice-id');

				$.ajax('/invoice/' + invoiceID, {
					type: 'get',
					data: {'_token': csrf_token},
					success: function(response) {
						if (response.error) {
							alert('Error: ' + response.message);
						}
						else {
							$('#invoicemodalid').html(response.invoice_id);
							$('#invoiceModalHistory').html(response.view);
							$('#invoiceModal').modal('show');
						}
					},
					error: function(xhr, status, error) {
						alert('System Error: ' + error);
					}
				});
			})

		},

		payInvoice: function() {
			var self = this;

			$('.invoice-pay').bind('click', function(e) {
				e.preventDefault();
				$.ajax($(this).attr('data-url'), {
					type: 'post',
          headers: {'X-CSRF-TOKEN': getCsrfToken()},
          success: function(response) {
						if (response.error) {
							alert('Error: ' + response.message);
							return '';
						}
						else {
							window.location.href = '/cart';
						}
					},
					error: function(xhr, status, error) {
						alert('System Error: ' + error);
					}
				});

				$(this).unbind('click');
				$(this).text('Cart').removeClass('invoice-pay');
				$(this).attr('href', "/cart");
				$(this).data('url', "");
			});

		},
  };

  window.CartOrders = CartOrdersImpl;

})();
