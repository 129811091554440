/**
 * Created by mjwunderlich on 6/11/15.
 */

(function() {

  /**
   * When video is in cart, show the View Cart button
   * When video is not in cart, show the Add to Cart button
   *
   * @type {{}}
   */

  var VideosImpl = {

    _addToCartButton: 'a.add-to-cart',
    _viewCartButton: 'a.go-to-checkout',

    init: function() {
      var self = this;
      ShoppingCart.setGlobalRemoveCallback(function(productId) {
        self._removeCallback(productId);
      });
    },

    showAddToCartButton: function(productId) {
      var videoItem = $('#video-item-' + productId);
      $(this._addToCartButton, videoItem).show();
      $(this._viewCartButton, videoItem).hide();
    },

    showViewCartButton: function(productId) {
      var videoItem = $('#video-item-' + productId);
      $(this._addToCartButton, videoItem).hide();
      $(this._viewCartButton, videoItem).show();
    },

    _removeCallback: function(productId) {
      this.showAddToCartButton(productId);
    },

    bootstrapUI: function() {
      var self = this;

      $('a.add-to-cart').unbind('click').click(function (event) {
        event.preventDefault();

        var productId = $(this).attr('data-product-id');
        ShoppingCart.addToCart( productId, function() {

          self.showViewCartButton(productId);

        } );
      });
    }

  };

  // Export
  window.Videos = VideosImpl;

})();
