/**
 * Created by mjwunderlich on 6/5/15.
 */

(function() {

  var CheckoutUIImpl = {

    _oneTimePayment: '.one-time-payment',
    _passwordFields: '#password-fields',
    _passwordSecret: 'input#password-secret',
    _passwordText: 'input#password-text',
    _showPassword: 'input#show-password',
    _createAccount: '#create-account',
    _checkoutGuest: '#checkout-guest',

    _showOneTimePayment: '.show-one-time-payment',
    _hideOneTimePayment: '.hide-one-time-payment',

    _ccList: 'section.card-info',

    _firstName: 'input#first_name',
    _lastName: 'input#last_name',
    _email: 'input#email',
    _billingCountry: 'select#billing_country',
    _billingCity: 'input#billing_city',
    _billingStateSelect: 'select#billing_state',
    _billingStateInput: 'input#billing_state_free_form',
    _ticketFirstName: 'input.ticket-first-name',
    _ticketLastName: 'input.ticket-last-name',
    _ticketEmail: 'input.ticket-email',
    _firstTicketFirstName: '.tickets .ticket:first input.ticket-first-name',
    _firstTicketLastName: '.tickets .ticket:first input.ticket-last-name',
    _firstTicketEmail: '.tickets .ticket:first input.ticket-email',

    fireCreateAccount: function() {
      $(this._createAccount).hide();
      $(this._passwordFields).css("display","inline");
    },

    fireCheckoutGuest: function() {
      $(this._passwordFields).hide();
      $(this._createAccount).css("display","inline");
    },

    showOneTimePayment: function() {
      CheckoutVAT.clearVAT();
      $(this._oneTimePayment).show();
      CheckoutVAT.updateVATTax(true);
    },

    hideOneTimePayment: function() {
      CheckoutVAT.clearVAT();
      $(this._oneTimePayment).hide();
      CheckoutVAT.updateVATTax(true);
    },

    hideCCList: function() {
      $(this._ccList).hide();
    },

    showPasswordSecret: function() {
      var self = this;

      $(this._passwordSecret).show();
      $(this._passwordText).hide().unbind('change');

      $(this._passwordSecret).unbind('change').change(function() {
        $(self._passwordText).val( $(this).val() );
        self.passwordsChanged();
      });
    },

    showPasswordText: function() {
      var self = this;

      $(this._passwordText).show();
      $(this._passwordSecret).hide().unbind('change');

      $(this._passwordText).unbind('change').change(function() {
        $(self._passwordSecret).val( $(this).val() );
        self.passwordsChanged();
      });
    },

    passwordsChanged: function() {
      var password = $(this._passwordText).val();
      if (password != '' && password.length) {
        $('.show-only-if-password-set').show();
      }
      else {
        $('.show-only-if-password-set').hide();
      }
    },

    autoPopulateTickets: function() {
      var self = this;

      $(this._firstName).unbind('change').change(function() {
        $(self._firstTicketFirstName + '[value=""]').val( $(this).val() );
      });

      $(this._lastName).unbind('change').change(function() {
        $(self._firstTicketLastName + '[value=""]').val( $(this).val() );
      });

      $(this._email).unbind('change').change(function() {
        $(self._firstTicketEmail + '[value=""]').val( $(this).val() );
      });

      $(this._firstName).change();
      $(this._lastName).change();
      $(this._email).change();
    },

    bootstrapUI: function() {
      var self = this;

      $(this._createAccount).click(function() {
        self.fireCreateAccount();
      });

      $(this._checkoutGuest).click(function() {
        self.fireCheckoutGuest();
      });

      // Any element with the .show-one-time-payment class will, when clicked, show the one-time-payment fields
      $(this._showOneTimePayment).unbind('click').click(function (event) {
        self.showOneTimePayment();
      });

      // Any element with the .hide-one-time-payment class will, when clicked, hide the one-time-payment fields
      $(this._hideOneTimePayment).unbind('click').click(function (event) {
        self.hideOneTimePayment();
      });

      // When the show-password checkbox changes, toggle between text/password fields
      $(this._showPassword).unbind('change').change(function () {
        if ( $(this).is(':checked') ) {
          self.showPasswordText();
        }
        else {
          self.showPasswordSecret();
        }
      });

      this.autoPopulateTickets();

      // Trigger the change() handler
      $(this._showPassword).change();

      // Setup the Country / State logic
      $(this._billingCountry).change(function () {
        var val = self.getBillingCountry();
        var known = ['us', 'ca', 'gb', 'ie'];
        if (known.indexOf(val) >= 0) {
          $(self._billingStateSelect).parent("div").parent("div").show();
          $(self._billingCity).parent("div").parent("div").removeClass("col-lg-8").addClass("col-lg-4");
          $(self._billingCity).parent("div").parent("div").removeClass("col-md-8").addClass("col-md-4");
        }
        else {
          $(self._billingStateSelect).parent("div").parent("div").hide();
          $(self._billingCity).parent("div").parent("div").removeClass("col-lg-4").addClass("col-lg-8");
          $(self._billingCity).parent("div").parent("div").removeClass("col-md-4").addClass("col-md-8");
        }

        // When changing the Country, also update the VAT tax
        CheckoutVAT.updateVATTax(true);
      });

      // Trigger change() handler
      $(this._billingCountry).change();
    },

    getBillingCountry: function() {
      var cc = $(this._hideOneTimePayment + ':checked');
      if (cc.length) {
        var country = cc.data('cc-country').toLowerCase();
        return country;
      }

      var country = $('#billing_country').val();

      if (!country) {
          return 'us';
      }

      return country.toLowerCase();
    }
  };

  // Export
  window.CheckoutUI = CheckoutUIImpl;

})();
