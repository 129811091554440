/**
 * Created by mjwunderlich on 5/26/15.
 */

(function() {

  var CartImpl = {

    _productDecrQuantityBtn: '.cart-decr-quantity',
    _productIncrQuantityBtn: '.cart-incr-quantity',
    _productQuantityText: '.product-quantity',
    _addToCartBtn: '.add-to-cart a',
    _addToCartCurrencyBtn: '.add-to-cart-currency a',
    _modalCurrencyNoMatch: '#modalEventCurrency',

    bootstrap: function() {
      var self = this;

      // Set the default quantity
      $(this._productQuantityText).text(1);

      // Decrease quantity button
      $(this._productDecrQuantityBtn).unbind('click').click(function (event) {
        event.preventDefault();
        self.setQuantity( self.getQuantity() - 1 );
      });

      // Increase quantity button
      $(this._productIncrQuantityBtn).unbind('click').click(function (event) {
        event.preventDefault();
        self.setQuantity( self.getQuantity() + 1 );
      });

      // Add to cart button
      $(this._addToCartBtn).unbind('click').click(function (event) {
        event.preventDefault();
        self.addToCart( $(this).attr('data-product-id') );
      });

        // Check Currency / Add to Cart
      $(this._addToCartCurrencyBtn).unbind('click').click(function (event) {
        event.preventDefault();
        self.checkCurrency( $(this).attr('data-product-id'), parseInt($(this).attr('data-status')) );
      });

    },

    getQuantity: function() {
      var quantity = $(this._productQuantityText).text() || 1;
      return parseInt( quantity );
    },

    setQuantity: function(quantity) {
      if (quantity < 1) {
        quantity = 1;
      }
      $(this._productQuantityText).text( quantity );
    },

    addToCart: function(productId) {
      var quantity = this.getQuantity();
      window.location.href = '/cart/add/single/' + productId + '?quantity=' + quantity;
    },


    /**
     *	Compare the Product's Currency with the Cart Currency. For events,
     *	they must match
     *
     *	@require
     */
    checkCurrency: function (productId, status) {
      if(!status) {
          $(".add-to-cart-currency .error").html('Event not available for purchase');
          return;
      }
      var productId = productId;
      var self = this;

      $.ajax("{{ url('cart/check-currency') }}" + productId, {
        type: 'GET',
        success: function () {
          self.addToCart(productId);
        },
        error: function () {
          $(self._modalCurrencyNoMatch).modal('show');
        }
      });
    },
  };

  // Export
  window.Cart = CartImpl;

})();