(function() {

  var ShoppingCartImpl = {
    _cart: '.cart-items-list',
    _cartFooter: '.cart-items-list .envelope .footer',
    _cartItems: '.cart-items-list .envelope table.items',
    _cartSubtotal: '.cart-items-list .envelope .footer .subtotal',
    _cartItemsCount: '.cart-new span.cart-items',

    _cartEnabledCheckoutBtn: '.cart-items-list .footer .btn-checkout',
    _cartDisabledCheckoutBtn: '.cart-items-list .footer .btn-checkout-disabled',

    _purchaseRemove: '.dd-purchase-remove a',
    _cachedPurchaseIds: [],
    _removeCallback: null,

    init: function() {
      this._bootstrapUI();
      this._updateCart(null);
    },

    getNumberOfItemsInCart: function() {
      var quantity = parseInt( $( this._cartItemsCount + ':first').text() );
      return quantity;
    },

    addToCart: function(product_id, callback) {
      var self = this;
      var url = '/cart/addPurchaseAndRender/' + product_id;
      this._post(url, { _token: '' }, function(response) {
          // Show the new purchased item
          var template = Utils.Template.grabTemplateFromJson(response.purchase_html_drop_down);
          self._renderNewPurchase(template);
          self._updateCart(response);
          self._showEnabledCartButton();
          self._enableDropDownCart();

          self._cachedPurchaseIds = self.getPurchasedProductsIds();

          // Highlight the cart icon
          $('.cart-new').highlight();

          callback && callback();
      });
    },

    removeFromCart: function(productId) {
      var self = this;
      this._post('/cart/remove/' + productId, {quantity: 'all'}, function(response) {
        $('table#dd-purchase-' + productId).fadeOut(400, function() {
          $(this).remove();
          self._updateCart(response);
          if (self.getNumberOfItemsInCart() <= 0) {
            self._showDisabledCartButton();
            self._disableDropDownCart();
          }

          self._cachedPurchaseIds = self.getPurchasedProductsIds();
          self._removeCallback && self._removeCallback(productId, response);
        });
      });
    },

    setGlobalRemoveCallback: function(callback) {
      this._removeCallback = callback;
    },

    getPurchasedProductsIds: function() {
      // The purchase-remove link contains a data-product-id attribute
      // We'll compile an array of these ids
      var purchaseIds = [];

      $( this._purchaseRemove).each( function (index, link) {
        var id = $(link).attr('data-product-id');
        purchaseIds.push( id );
      });

      return purchaseIds;
    },

    isProductInCart: function(id) {
      var purchasedIds = this.getPurchasedProductsIds();
      var index = 0;
      for ( ; index < purchasedIds.length; ++index) {
        if (id == purchasedIds[index]) {
          return true;
        }
      }
      return false;
    },

    _bootstrapUI: function() {
      var self = this;
      $(this._cartItems).each(function (index, item) {
        self._bootstrapPurchase( item );
      });
    },

    _renderNewPurchase: function(template) {
      this._bootstrapPurchase(template);
      template.insertBefore( $(this._cartFooter) );
    },

    _bootstrapPurchase: function(item) {
      var self = this;

      item = $( item );
      $(this._purchaseRemove, item).unbind('click').click(function (event) {
        event.preventDefault();
        var productId = $(this).attr('data-product-id');
        self.removeFromCart(productId);
      });
    },

    _showError: function(message) {
      Notify.error(message + 'Please try again or contact customer support.');
    },

    _showDisabledCartButton: function() {
      $(this._cartEnabledCheckoutBtn).hide();
      $(this._cartDisabledCheckoutBtn).show();
    },

    _showEnabledCartButton: function() {
      $(this._cartEnabledCheckoutBtn).show();
      $(this._cartDisabledCheckoutBtn).hide();
    },

    _enableDropDownCart: function() {
      $(this._cart).removeClass('cart-no-show');
    },

    _disableDropDownCart: function() {
      $(this._cart).addClass('cart-no-show');
    },

    _updateCart: function(response) {
      var quantity = this.getNumberOfItemsInCart();

      if (response) {
        quantity = response.total_quantity;

        $(this._cartItemsCount).text(quantity);
        $(this._cartSubtotal).text(window[CartCheckoutUI.currencyMethod](response.subtotal));
      }

      if (quantity) {
				$('#mobSubscribeBtn').addClass('hidden');
				$('.cart-new').removeClass('mobilehide');
				$('.cart-new').removeClass('tablethide');
        this._showEnabledCartButton();
      }
      else {
        this._showDisabledCartButton();
      }
    },

    _post: function(url, data, callback) {
      var self = this;

      $.ajax(url, {
        type: 'post',
        headers: {'X-CSRF-TOKEN': getCsrfToken()},
        data: data,
        success: function(response) {
          if (response.error) {
            self._showError();
          }
          else {
            callback && callback(response);
          }
        },
        error: function(xhr, status, errorThrow) {
          self._showError(errorThrown + '.');
        }
      });
    }
  };

  // Export
  window.ShoppingCart = ShoppingCartImpl;

})();
