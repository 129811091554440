(function() {

  function StagedTicketImpl(id, first_name, last_name, email, line_item_id) {
    this.id = id;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.line_item_id = line_item_id;
    this.quantity = 1;
    this.committed = false;
  }

  StagedTicketImpl.prototype = $.extend({}, {
    render: function() {
      var template = Utils.Template.grabTemplate('#staged-ticket-template');
      $(template).addClass('staged-ticket-' + this.id );
      $('.staged-ticket-name', template).text( this._formattedName() );
      $('.staged-ticket-email', template).text( this._formattedEmail() );
      $('table#staged-tickets tbody').prepend( template );

      this._hideOriginalTicket(null);
    },

    renderRefunded: function() {
      var template = Utils.Template.grabTemplate('#refunded-ticket-template');
      $(template).attr('id', 'refunded-ticket-' + this.id );
      $('h3', template).text('Refunded Ticket ' + ($('#refunded-tickets .ticket.row').length + 1) );
      $('input[name="first_name"]', template).val( this.first_name );
      $('input[name="last_name"]', template).val( this.last_name );
      $('#refunded-tickets').append( template );
      template.fadeIn(400);
    },

    cancel: function() {
      var self = this;
      if (!this.committed) {
        this._showOriginalTicket(function () {
          $('.staged-ticket-' + self.id).remove();
        });
      }
      else {
        $('.staged-ticket-' + self.id).remove();
      }
    },

    commit: function() {
      var self = this;
      $('#ticket-' + this.id).fadeOut(400, function() {
        $(this).remove();
        self.renderRefunded();
      });
      this.committed = true;
    },

    _hideOriginalTicket: function(callback) {
      $('#ticket-' + this.id).animate({opacity: 0.2}, 400, callback)
    },

    _showOriginalTicket: function(callback) {
      $('#ticket-' + this.id).animate({opacity: 1.0}, 400, callback);
    },

    _formattedName: function() {
      if (this.first_name && this.last_name) {
        return this.first_name + ' ' + this.last_name;
      }
      else if (this.first_name) {
        return this.first_name;
      }
      else if (this.last_name) {
        return this.last_name;
      }
      else {
        return '–';
      }
    },

    _formattedEmail: function() {
      if (this.email) {
        return this.email;
      }
      else {
        return '–';
      }
    }
  });

  var TicketsImpl = {
    _stagedTickets: [],

    stageTicketForRefund: function(id, first_name, last_name, email, line_item_id) {
      var staged = new StagedTicketImpl(id, first_name, last_name, email, line_item_id);
      staged.render();
      this._stagedTickets.push( staged );
      this.showStagedTicketsArea();
    },

    clearAllStagedTickets: function() {
      var index = 0;
      for ( ; index < this._stagedTickets.length; ++index) {
        this._stagedTickets[ index].cancel();
      }
      this._stagedTickets.length = 0;
      this.hideStagedTicketsArea();
    },

    _prepareRefundLineItems: function() {
      var refund_line_items = {};
      var index = 0;
      for ( ; index < this._stagedTickets.length; ++index) {
        var staged_refund = this._stagedTickets[ index ];
        var line_item = refund_line_items[staged_refund.line_item_id];
        if (typeof line_item == 'undefined') {
          line_item = refund_line_items[staged_refund.line_item_id] = {
            id: staged_refund.line_item_id,
            quantity: 0,
            reason: 'Please refund these ticket(s).',
            product_ids: []
          };
        }

        line_item.quantity += staged_refund.quantity;
        line_item.product_ids.push(staged_refund.id);
      }

      return refund_line_items;
    },

    submitRefundRequest: function(csrf_token) {
      var refund_line_items = this._prepareRefundLineItems();
      var index;
      var self = this;
      for ( index in refund_line_items ) {
        var refund_line_item = refund_line_items[ index ];
        var url = '/order/lineItem/refund/' + refund_line_item.id;

        $.ajax(url, {
          type: 'post',
          headers: {'X-CSRF-TOKEN': getCsrfToken()},
          data: {
            _token: csrf_token,
            quantity: refund_line_item.quantity,
            reason: refund_line_item.reason,
            product_ids: refund_line_item.product_ids
          },
          success: function(response) {
            if (response.error) {
              Log.error('/order/lineItem/refund failed with message: ' + response.message);
              Log.error('Trace:' + response.trace);

              if (response.error_type == 'fatal') {
                Notify.error(response.message);
              }
              else if (response.error_type == 'recoverable') {
                Notify.error(response.message);
              }
            }
            else {
              Notify.success('Refunds processed successfully', { duration: 5000 });

              for (index = 0; index < self._stagedTickets.length; ++index) {
                self._stagedTickets[ index].commit();
              }
              self.clearAllStagedTickets();
            }
          },
          error: function() {
            Notify.error('Error while processing refunds');
            Log.error('/order/lineItem/refund failed with error: ' + error);
          }
        });
      }
    },

    showStagedTicketsArea: function() {
      $('div.confirm-refund').show();
    },

    hideStagedTicketsArea: function() {
      $('div.confirm-refund').hide();
    },

    setUpTypeahead: function(id, count, placeholder, appUrl, minLength, maxLength ) {
      const tix = document.querySelectorAll('.ticket-form')
      let target = ''

      if (tix) {
        const tickets = Array.from(tix)

        tickets.forEach((ticket) => {
          const index = tickets.indexOf(ticket)
          const select = ticket.querySelector('.js-type-ahead')

          //Give each select a unique id
          select.id = select.id + '--' + index
        })

        // Existing tickets vs new tickets
        if (id) {
          target = tickets.find((ticket) => id === ticket.getAttribute('data-ticket-id'))
        } else if (count) {
          target = tickets.find((ticket) => ticket.id === 'ticketformnew' + count)
        }

        // Set up typeahead
        if (target) {
          const typeAhead = target.querySelector('.js-type-ahead')
          $(typeAhead).select2({
              dropdownParent: $('.tickets-module'),
              ajax: {
                  url: function (params) {
                      // '/company' is the endpoint that needs to be called.
                      return (appUrl + '/company/' +  params.term);
                  },
                  delay: 250,
                  dataType: 'json',
                  processResults: function (data) {
                      return {
                          results: data
                      };
                  }
                  // Additional AJAX parameters go here; see the end of this chapter for the full code of this example
              },
              // No searching for tiny/empty strings
              minimumInputLength: minLength,
              // If you didn't find it by the 9th character, It doesn't exist.
              maximumInputLength: maxLength,
              placeholder: placeholder ? placeholder : 'Type to search for your company',
              tags: true,
              selectOnClose: true,
              width: '100%',
              escapeMarkup: function (text) { return text; },
          });
        }
      }
    },

    isTicketStaged: function(id) {
      var staged, index = 0;
      for ( ; index < this._stagedTickets.length; ++index) {
        staged = this._stagedTickets[index];
        if (staged.id == id) {
          return true;
        }
      }
      return false;
    },

    bootstrapUI: function() {
      var self = this;

      $('a.post-remove').each(function (index, link) {
        $(link).unbind('click').click(function (event) {
          var id = $(this).attr('ticket-id');
          if (self.isTicketStaged(id)) {
            return;
          }

          var line_item_id = $(this).attr('ticket-line-item-id');
          var first_name = $(this).attr('ticket-first-name');
          var last_name = $(this).attr('ticket-last-name');
          var email = $(this).attr('ticket-email');

          Tickets.stageTicketForRefund(id, first_name, last_name, email, line_item_id);
        });
      });

      $('a.clear-refunds').unbind('click').click(function (event) {
        event.preventDefault();
        self.clearAllStagedTickets();
      });

      $('.process-refund a').unbind('click').click(function (event) {
        event.preventDefault();
        var csrf_token = $(this).attr('data-token');
        self.submitRefundRequest(csrf_token);
      });

      $('.ticket-form').each(function (index, form) {
        $('input, select', $(form)).change(function () {
          $('.update-btn', $(form)).addClass('orange').removeClass('gray');
          $('.update-btn-text', $(form)).removeClass('hidden');
        });
      });

      $('.btn-update-all-tickets').unbind('click').click( function (e) {
        e.preventDefault();
        self.saveAllTickets();
      });
    },


    /**
     * Save All Tickets
     */
    saveAllTickets: function () {

      var url = '/order/ticket/all';
      var error_form = '';
      var tickets = {};
      var ticket = 0;

      // Get Each Ticket
      $('form.ticket-form').each( function () {
        var first_name = $(this).find('input[name=first_name]').val();
        var last_name = $(this).find('input[name=last_name]').val();
        var phone = $(this).find('input[name=phone]').val();
        var company = $(this).find('input[name=company]').val();
        var ticket_id = $(this).find('input[name=ticket_id]').val();

        var ticket_error = !first_name || !last_name || !phone || !company;

        if (!ticket_id) {}
        else if (error_form == '' && ticket_error) {
          error_form = this;
        }
        else if (ticket_error) {}
        else {
          tickets[ticket] = $( this ).serializeObject();
          ticket = ticket + 1;
        }
      });

      $.ajax(url, {
        type: 'post',
        headers: {'X-CSRF-TOKEN': getCsrfToken()},
        data: {tickets: tickets},
        success: function (response) {
          if (response.error) {
            Notify.error( response.message );
          }
          else if (error_form == '') {
            Notify.success( 'Tickets Saved Successfully!' );
          }
          else {
            Notify.error( 'Some Tickets Saved, please check for errors!' );
          }
          return true;
        },
        error: function(xhr, status, errorThrown) {
          Notify.error( errorThrown );
          Log.info('ResponseText: ' + xhr.resopnseText );
        }
      });

      if (error_form == '') {
        return true;
      }

      var validation = $(error_form).validate({
        errorClass: 'validationError',

        highlight: function (element, errorClass, validClass) {
          $(element).parent('div').addClass(errorClass);
          $('.error', $(element).parent('div')).show();
        },

        unhighlight: function (element, errorClass, validClass) {
          $(element).parent('div').removeClass(errorClass);
          $('.error', $(element).parent('div')).hide();
        },

        errorPlacement: function(error,element) {
          // Display the error message
          $('.formError', $(element).parent('div')).text( $(error).text() );
        },
      });
      $(error_form).submit();

    },

    setupValidation: function() {
      $('.ticket-form').each(function (index, form) {
        var id = '#' + $(form).attr('id');
				
				// Add functionality to clear button
				$(id + " .clear-ticket").click({id: id, form: form}, function (e) {
					e.preventDefault();
          var data = Utils.Form.serialize(e.data.id);
          var url = '/order/ticket/clear/' + $(e.data.form).attr('data-ticket-id');
          $.ajax(url, {
            type: 'post',
            headers: {'X-CSRF-TOKEN': getCsrfToken()},
            data: data,
            success: function (response) {
              if (response.error) {
                Notify.error( response.message );
              }
              else {
                Notify.success( 'Ticket cleared successfully!' );
								window.location.href = response.redirect;
              }
							return true;
            },
            error: function(xhr, status, errorThrown) {
              Notify.error( errorThrown );
              Log.info('ResponseText: ' + xhr.resopnseText );
            }
          })
				});

				// Save Individual Ticket
        Utils.Validation.getOrSetupFormValidation(id, {}, function (form) {
          // Submit this request using ajax
          var data = Utils.Form.serialize(id);
          var url = '/order/ticket/' + $(form).attr('data-ticket-id');
          $.ajax(url, {
            type: 'post',
            headers: {'X-CSRF-TOKEN': getCsrfToken()},
            data: data,
            success: function (response) {
              if (response.error) {
                Notify.error( response.message );
              }
              else {
                Notify.success( 'Ticket updated successfully!' );
                $('.update-btn', $(form)).removeClass('orange').addClass('gray');
                $('.update-btn-text', $(form)).addClass('hidden');
              }
            },
            error: function(xhr, status, errorThrown) {
              Notify.error( errorThrown );
              Log.info('ResponseText: ' + xhr.resopnseText );
            }
          })
        });
      });
    }


  };

  // Export
  window.Tickets = TicketsImpl;

})();
