/**
 * Created by mjwunderlich on 6/9/15.
 */

(function() {

  var CheckoutProcessImpl = {
    assignValidationMessages: function(errors, form_id) {
			this.showErrors(errors, form_id);
    },

		showErrors: function (response, form_id) {

			// Remove all error fields
      this.hideErrors();

			var scrollDistance = 0;
      if (!response.formError) {

        if (response.redirect) {
          window.location.href = response.redirect;
          return;
        }
        else {
          response.formError = [{
            id: 'payment-info',
            message: response.message || 'An unexpected error has occurred. Please try again, or contact Bisnow customer service for assistance.'
          }];
        }
      }

			// Show Each Error Field
			$.each(response.formError, function (i,v) {

				if (!v.wrapper) {
					v.wrapper = 'div';
				}

        var errorLocation = false;

				// Check if Field or ID
				if (v.field) {
					errorLocation = '#' + form_id + ' input[name=' + v.field + ']';
				}
				else if (v.input) {
					errorLocation = '#' + form_id + ' input[name="' + v.input + '"]';
					if (form_id == 'checkout-form') {
						errorLocation = '#' + form_id + ' #' + v.input;
					}
				}
				else if (v.id) {
          if (v.id == 'payment-info') {
            errorLocation = '.'+ v.id + '.manualError';
          }
          else {
            errorLocation = '#' + v.id;
          }
				}

				if (errorLocation && errorLocation.length) {
					$(errorLocation).parent('.inputText').addClass('validationError');
					$(errorLocation).parent('.inputSelect').addClass('validationError');

					if (v.message) {
						$(errorLocation).after('<' + v.wrapper + ' class="formError ' + v['class'] + '">' + v.message + '</' + v.wrapper + '>');
            $(errorLocation).show();
					}

          // Determine scroll distance.
          // IMPORTANT: iterate through ALL elements matched by the error_location CSS selector, to choose the top-most
          // one. Especially with errors targeted to "payment-info", there are more than 1 displayed.
          $(errorLocation).each(function (index, element) {
            var top = $(element).offset().top - 100;
            if (!scrollDistance || top < scrollDistance) {
              scrollDistance = top;
            }
          });
        }
			});

      if (scrollDistance) {
        $('html, body').animate({
          scrollTop: scrollDistance
        }, 500);
      }
		},

		removeError: function() {
			$('.validationError').bind('click', function() {
				$(this).children('.formError').remove();
			})
		},

    showError: function(selector, message) {
      $('.' + selector).show();

      var messageSelector = '.' + selector + ' .formError .' + selector + '-message';
      if ( $(messageSelector).length == 0 ) {
        messageSelector = '.' + selector + ' .formError';
      }

      $(messageSelector).text(message);
    },

    hideErrors: function() {
      // Remove all error fields
      $('.formError').remove();
      $('.inputText').removeClass('validationError');

      // Hide manual errors (these are errors not bound to inputs)
      $('.manualError').hide();
    }
  };

  // Export
  window.CheckoutProcess = CheckoutProcessImpl;

})();
