(function () {

  ////////////////////////////////////////
  // Logic to show/hide the cart dropdown
  //

  // PRIVATE var _cartHideTimeout
  // Used to controll timer to close cart's drop down
  var _cartHideTimeout = null;

  // PRIVATE var _cartDropDownIsEnabled
  // If true then the dropdown is enabled
  var _cartDropDownIsEnabled = true;

  var _cartSelector = function () {
    return $('.cart-items-list');
  };

  var _cartSelectorOnlyIfShowable = function () {
    return $('.cart-items-list').not('.cart-no-show');
  };

  /**
   * PUBLIC cart_slideOpen
   * Causes the shopping cart to drop down, revealing all items.
   *
   * @param long duration optional duration (ms) after which cart will slide up.
   *
   */
  window.cart_slideOpen = function (duration) {
    clearTimeout(_cartHideTimeout);
    var height = $('#header').height();
    _cartSelector().css('top', height);
    if ($(window).width() >= 480) {
      _cartSelectorOnlyIfShowable().slideDown(100, function () {
        if (duration) {
          _cartHideTimeout = setTimeout(function () {
            _cartSelector().slideUp(100);
          }, duration);
        }
      });
    }
  };

  /**
   * PUBLIC cart_disableDropDown
   * Disables the shopping cart drop-down.
   *
   */
  window.cart_disableDropDown = function () {
    _cartDropDownIsEnabled = false;

    // Make sure the cart is hidden
    _cartSelector().hide();
    if (_cartHideTimeout) {
      clearTimeout(_cartHideTimeout);
      _cartHideTimeout = null;
    }
  };

    /**
     * PUBLIC cart_initDropDown
     */
  window.cart_initDropDown = function () {
      // Do not add cart hover states on mobile
      if (isMobileBrowser()) {
          var cart = $('.cart-new');
          cart.click(function () {
              window.location.href = '/cart';
          });
          return;
      }

      if (!!('ontouchstart' in window)) {
          $('.cart-new, .cart-items-list').on('touchstart', showCartTouch);
          $('.cart-items-list, .cart-items-list *').on('touchstart', showCartItems);
          $('.cart-hide').on('touchstart', hideCartTouch);
      }
      else {
          $('#cart-icon-desktop').hover(showCart, showCartTimeout);
          $('.cart-new').hover(showCart, showCartTimeout);
          $('.cart-items-list').hover(showCartItems, showCartTimeout);
      }
  };

  var showCart = function () {
    if (!_cartDropDownIsEnabled) {
      return;
    }
    clearTimeout(_cartHideTimeout);
    _cartSelectorOnlyIfShowable().show();
  };

  var showCartTimeout = function () {
    clearTimeout(_cartHideTimeout);
    _cartHideTimeout = setTimeout(function () {
      _cartSelector().hide();
    }, 100);
  };

  var showCartItems = function () {
    if (!_cartDropDownIsEnabled) {
      return;
    }
    clearTimeout(_cartHideTimeout);
  };

  var showCartTouch = function () {
    if (!_cartDropDownIsEnabled) {
      return;
    }
    if (_cartSelector().is(":visible")) {
      hideCartTouch();
      return;
    }
    clearTimeout(_cartHideTimeout);
    var height = $('#header').height();
    _cartSelector().css('top', height);
    _cartSelectorOnlyIfShowable().show();
    $('.cart-items-list.cart-auto-hide').delay(2000).hide(0);
    $('.cart-hide').show();
  };

  var hideCartTouch = function () {
    if (!_cartDropDownIsEnabled) {
      return;
    }
    clearTimeout(_cartHideTimeout);
    _cartHideTimeout = setTimeout(function () {
      _cartSelector().hide();
      $('.cart-hide').hide();
    }, 10);
  };

  $(function () {
      cart_initDropDown();
  });

})();
